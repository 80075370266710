// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[aria-sort] .ag-sorting > .wpp-icon {
  display: none;
}
[aria-sort='none'] .ag-sorting > .wpp-icon.sortIcon {
  display: block;
}
[aria-sort='ascending'] .ag-sorting > .wpp-icon.ascSortIcon,
[aria-sort='descending'] .ag-sorting > .wpp-icon.descSortIcon {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/pages/agency-dashboard/AgencyAgGrid/components/headerCell.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB;AACA;;EAEE,cAAc;AAChB","sourcesContent":["[aria-sort] .ag-sorting > .wpp-icon {\n  display: none;\n}\n[aria-sort='none'] .ag-sorting > .wpp-icon.sortIcon {\n  display: block;\n}\n[aria-sort='ascending'] .ag-sorting > .wpp-icon.ascSortIcon,\n[aria-sort='descending'] .ag-sorting > .wpp-icon.descSortIcon {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
