// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JTOHH{color:var(--color-font-grey)}.oE_3_::part(content){width:150px}.oE_3_::part(actions-wrapper){padding:.5rem}.sYsyV,.sYsyV::part(datepicker-container){width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/agency-dashboard/agencyDashboard.module.scss"],"names":[],"mappings":"AAAA,OACI,4BAAA,CAGJ,sBACI,WAAA,CAEJ,8BACI,aAAA,CAGJ,0CACK,UAAA","sourcesContent":[".greyColor{\n    color: var(--color-font-grey);\n}\n\n.editDeleteModal::part(content){\n    width: 150px\n}\n.editDeleteModal::part(actions-wrapper){\n    padding: .5rem;\n}\n\n.datePicker, .datePicker::part(datepicker-container){\n     width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greyColor": `JTOHH`,
	"editDeleteModal": `oE_3_`,
	"datePicker": `sYsyV`
};
export default ___CSS_LOADER_EXPORT___;
