import { useOs } from '@wppopen/react'
import { Navigate, Outlet } from 'react-router-dom'

import { agencyRoles } from 'constants/agencyRoles'

export const AgencyRoutes = () => {
  const {
    osContext: { permissions },
  } = useOs()
  const hasPermission = permissions?.[0]?.permissions.includes(agencyRoles.RFI_AGENCY_MANAGE)
  return hasPermission ? <Outlet /> : <Navigate to="/" />
}
