// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QorFX::part(textarea){color:var(--color-font-grey);font-weight:500;font-size:12px;font-family:TCCC-UnityHeadline,sans-serif;font-style:normal;line-height:16px}`, "",{"version":3,"sources":["webpack://./src/components/chat/conversation-message/ConversationMessage.module.scss"],"names":[],"mappings":"AACE,uBACE,4BAAA,CACA,eAAA,CACA,cAAA,CACA,yCAAA,CACA,iBAAA,CACA,gBAAA","sourcesContent":[".textarea {\n  &::part(textarea) {\n    color: var(--color-font-grey);\n    font-weight: 500;\n    font-size: 12px;\n    font-family: TCCC-UnityHeadline, sans-serif;\n    font-style: normal;\n    line-height: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `QorFX`
};
export default ___CSS_LOADER_EXPORT___;
