import { ListItemChangeEventDetail } from '@wppopen/components-library'
import { WppListItemCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppAvatar,
  WppTypography,
  WppDivider,
  WppAvatarGroup,
  WppListItem,
  // WppTag,
  WppMenuContext,
  WppActionButton,
  WppIconMore,
} from '@wppopen/components-library-react'
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDeleteRfi } from 'api/mutations/rfis/useDeleteRfi'
import { useMarkets } from 'api/queries/markets/useMarkets'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { queryClient } from 'app/Root'
import { navigation } from 'components/SidebarCmp'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import useProjectContext from 'hooks/useProjectContext'
import { useToast } from 'hooks/useToast'
import { CreatedOrUpdatedBy, RfiAgency, RfiMember } from 'types/rfis/rfi'

import style from './home.module.scss'

export default function ProjectPreviewCard({
  projectName,
  agencies,
  pitchTypeId,
  updatedAt,
  createdAt,
  createdBy,
  marketIds,
  projectMembers,
  id,
  rfiSummary,
}: Readonly<{
  projectName: string
  activeStatus: number
  clientName: string
  pitchTypeId: string
  updatedAt: string
  createdAt: string
  createdBy: CreatedOrUpdatedBy
  isCompleted: boolean
  id: string
  agencies: RfiAgency[]
  projectMembers: RfiMember[]
  marketIds: string[]
  rfiSummary: string
}>) {
  const navigate = useNavigate()
  const { data: pitchTypes } = usePitchTypes()
  const { data: allMarkets } = useMarkets()
  const { setState } = useProjectContext()
  const listContainerRef = useRef<HTMLDivElement>(null)
  const [listWidth, setListWidth] = useState<number>(0)
  const { mutateAsync: deleteProject } = useDeleteRfi()
  const toast = useToast()

  const pitchType = pitchTypes?.find(p => p.id === pitchTypeId)?.typeDescription
  const markets =
    marketIds?.reduce((accum, id, index, arr) => {
      const isLastOrFirstOfOnly1 = index === arr.length - 1 || (index === 0 && arr.length === 1)
      const market = allMarkets?.find(m => m.id === id)?.name
      if (market) {
        accum += `${market}${!isLastOrFirstOfOnly1 ? ', ' : ''}`
      }
      return accum
    }, '') || ''

  const agenciesString =
    agencies?.reduce((accum, agency, index, arr) => {
      const isLastOrFirstOfOnly1 = index === arr.length - 1 || (index === 0 && arr.length === 1)
      accum += `${agency?.name}${!isLastOrFirstOfOnly1 ? ', ' : ''}` || ''
      return accum
    }, '') || ''

  const members = projectMembers?.filter(member => member?.memberDetail?.email === createdBy?.email)

  const handleEditProjectClick = (e: SyntheticEvent | WppListItemCustomEvent<ListItemChangeEventDetail>) => {
    if ((e?.target as HTMLElement)?.dataset?.menu) return
    e.preventDefault()
    setState(prev => ({
      ...prev,
      projectId: id,
      rfiSummary,
    }))
    navigate(`${navigation.rfiSummary}/${id}`)
  }

  const handleDeleteProjectClick = async (id: string) => {
    try {
      await deleteProject(id)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFIS] })
      toast.showToast({ message: 'Project deleted successfully', type: 'success' })
    } catch (e) {
      toast.showToast({ message: 'Error deleting project', type: 'error' })
    }
  }

  const handleContainerWidth = useCallback(() => {
    if (listContainerRef.current) {
      setListWidth(listContainerRef.current.offsetWidth)
    }
  }, [])

  useEffect(() => {
    handleContainerWidth()
    window.addEventListener('resize', handleContainerWidth)
    return () => {
      window.removeEventListener('resize', handleContainerWidth)
    }
  }, [handleContainerWidth])

  return (
    <div
      className="w-[calc(100%/3-1rem)] flex flex-col gap-4 rounded-8 p-4 bg-[#F8F9FB] hover:cursor-pointer hover:bg-[#e9eaeb] hover:shadow transition-all duration-200 ease-in-out"
      onClick={handleEditProjectClick}
    >
      <div className="flex flex-row justify-between relative">
        <WppTypography className="line-clamp-2 overflow-hidden text-ellipsis w-5/6" type="xl-heading">
          {projectName}
        </WppTypography>
        <WppMenuContext className="w-1/6 absolute top-0 right-0 -mr-11" data-menu>
          <WppActionButton className="ml-auto" slot="trigger-element" data-menu>
            <WppIconMore direction="horizontal" data-menu />
          </WppActionButton>
          <div>
            <WppListItem onWppChangeListItem={handleEditProjectClick}>
              <p slot="label">Edit</p>
            </WppListItem>
            <WppListItem onWppChangeListItem={() => handleDeleteProjectClick(id)}>
              <p slot="label">Delete</p>
            </WppListItem>
          </div>
        </WppMenuContext>
      </div>
      <div className="mt-auto" ref={listContainerRef}>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">Last saved: {new Date(updatedAt || createdAt).toLocaleString()}</p>
        </WppListItem>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">Agencies: {agenciesString}</p>
        </WppListItem>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">Market: {markets}</p>
        </WppListItem>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">Pitch Type: {pitchType}</p>
        </WppListItem>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">
            Created By: <WppAvatar src={createdBy?.img ?? ''} name={createdBy?.name || ''} /> {createdBy?.name}
          </p>
        </WppListItem>
        <WppDivider className="mt-2 mb-2" />
        <div className="flex items-end">
          {members && (
            <WppAvatarGroup
              className="pt-2"
              maxAvatarsToDisplay={3}
              size="xs"
              withTooltip
              avatars={members?.map(member => ({
                name: member.memberDetail.name,
                src: member.memberDetail.img ?? '',
              }))}
            />
          )}
          {/* {Boolean(activeStatus) && (
            <WppTag className="ml-auto" variant="positive" label={activeStatus === 1 ? 'Done' : 'In Progress'} />
          )} */}
        </div>
      </div>
    </div>
  )
}
