import { WppTypography } from '@platform-ui-kit/components-library-react'
import { WppIconTableSort, WppIconTableSortAsc, WppIconTableSortDesc } from '@wppopen/components-library-react'
import { IHeaderParams } from 'ag-grid-community'
import { FunctionComponent } from 'react'
import './headerCell.css'

export const HeaderCell: FunctionComponent<IHeaderParams> = props => {
  const handleHeaderCellClick = () => {
    if (!props.enableSorting) return

    const items: ('asc' | 'desc' | null)[] = ['asc', 'desc', null]
    const selectedSort = props.column.getSort()
    const selectedSortIndex = selectedSort ? items.indexOf(selectedSort) : -1
    const nextSortType =
      selectedSortIndex === -1 || selectedSortIndex === items.length - 1 ? 'asc' : items[selectedSortIndex + 1]

    props.setSort(nextSortType)
  }

  return (
    <div className="ag-header-cell-comp" onClick={() => handleHeaderCellClick()}>
      <WppTypography type="s-strong">{props.displayName}</WppTypography>
      {props.enableSorting && (
        <div className="ag-sorting">
          <WppIconTableSort className="sortIcon" />
          <WppIconTableSortAsc className="ascSortIcon" />
          <WppIconTableSortDesc className="descSortIcon" />
        </div>
      )}
    </div>
  )
}

export default HeaderCell
