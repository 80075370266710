/* eslint-disable react-hooks/exhaustive-deps */
import { WppTypography } from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import { usePatchRfiQuestionId } from 'api/mutations/rfis/usePatchRfiQuestionId'
import { getRfiQuestionById } from 'api/queries/rfis/useRfiQuestionId'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { queryClient } from 'app/Root'
import { LoaderProgressWithDescription, ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import useRfiQuestionContext from 'hooks/useRfiQuestionContext'
import { RfiQuestion } from 'types/rfis/rfi'

import styles from './Chat.module.scss'
import { ConversationMessage } from './conversation-message/ConversationMessage'
import { QuestionInputNew } from './question-input/QuestionInputNew'

export enum MessageType {
  QUESTION = 'question',
  ANSWER = 'answer',
}

export interface Message {
  id: string
  type: MessageType
  content: string
  timestamp: string
}

export default function ChatCmp() {
  const { rfiQuestionId } = useRfiQuestionContext()
  const [{ data: rfiQuestion, isLoading }, trigger] = getRfiQuestionById()
  const refScrollBottomDiv = useRef<HTMLDivElement>(null)
  const [question, setQuestion] = useState('')
  const [task, setTask] = useState<ProgressApiRes | null>(null)

  const [conversation, setConversation] = useState<Message[]>([])
  const { mutateAsync: editProject, isPending } = usePatchRfiQuestionId()
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: task?.id || '' },
    enabled: !!task?.id,
    refetchInterval: 2000,
  })

  const scrollToBottom = () => {
    const scrollImmediateTimeout = setTimeout(() => {
      if (conversation) {
        refScrollBottomDiv.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }
    }, 10)

    return () => {
      clearTimeout(scrollImmediateTimeout)
    }
  }

  const updateCacheQueries = async (proposedAnswer: string) => {
    queryClient.cancelQueries({ queryKey: [ApiQueryKeys.RFI_QUESTIONS] })
    const prevQuestions = queryClient.getQueriesData({ queryKey: [ApiQueryKeys.RFI_QUESTIONS] })
    if (prevQuestions.length === 1) {
      const { data: questions } = prevQuestions[0][1] as { data: RfiQuestion[] }
      const updatedQuestions = questions.map(question => {
        if (question.id === rfiQuestionId) {
          return {
            ...question,
            proposedAnswer,
          }
        }
        return question
      })
      queryClient.setQueriesData({ queryKey: [ApiQueryKeys.RFI_QUESTIONS] }, (old: any) => {
        return {
          ...old,
          data: updatedQuestions,
        }
      })
    }
  }

  const handleEditProject = async (questionText: string) => {
    try {
      const res = await editProject({
        question_id: rfiQuestionId || '',
        questionText: questionText,
      })
      setTask(res.data)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSubmitQuestion = async (questionValue: string) => {
    setQuestion('')
    setConversation(conversationDraft => {
      const newMessage = {
        id: '1',
        type: MessageType.QUESTION,
        content: questionValue,
        timestamp: new Date().toISOString(),
      }
      return [...conversationDraft, newMessage]
    })
    scrollToBottom()

    handleEditProject(questionValue)
  }

  const handleQuestionInput = (value: string) => {
    setQuestion(value)
  }

  useEffect(() => {
    if (taskStatus.completed && rfiQuestionId) {
      setTask(null)
      trigger({ question_id: rfiQuestionId })
      return
    }
    if (!taskStatus.completed) {
      setTask(taskStatus)
    }
  }, [taskStatus])

  useEffect(() => {
    if (rfiQuestionId) {
      trigger({ question_id: rfiQuestionId })
    }
  }, [rfiQuestionId])

  useEffect(() => {
    if (rfiQuestion?.id === rfiQuestionId) {
      setConversation([])
    }
  }, [rfiQuestion?.id])

  useEffect(() => {
    if (rfiQuestion?.proposedAnswer && conversation.length % 2 === 1) {
      updateCacheQueries(rfiQuestion.proposedAnswer)
      setConversation(conversationDraft => {
        const newMessage = {
          id: Math.random().toString(),
          type: MessageType.ANSWER,
          content: rfiQuestion.proposedAnswer,
          timestamp: new Date().toISOString(),
        }
        return [...conversationDraft, newMessage]
      })
      scrollToBottom()
    }
  }, [rfiQuestion?.proposedAnswer])

  useEffect(() => {
    if (conversation.length === 0 && rfiQuestionId && !question) {
      if (rfiQuestion?.proposedAnswer && rfiQuestion?.questionText) {
        const answer = {
          id: Math.random().toString(),
          type: MessageType.ANSWER,
          content: rfiQuestion.proposedAnswer,
          timestamp: new Date().toISOString(),
        }
        const question = {
          id: Math.random().toString(),
          type: MessageType.QUESTION,
          content: rfiQuestion.questionText,
          timestamp: new Date().toISOString(),
        }
        setConversation([question, answer])
        return
      }
      if (rfiQuestion?.questionText && !rfiQuestion?.proposedAnswer) {
        handleSubmitQuestion(rfiQuestion.questionText)
        return
      }
    }
  }, [conversation, rfiQuestionId, question])

  return (
    <div className={styles.chatContainer}>
      {!rfiQuestion?.id && (
        <div className="flex flex-row justify-center items-center text-center">
          <WppTypography type="s-strong">
            Select a Suggested Question on the Left to get Proposed Answer, or Type Manually
          </WppTypography>
        </div>
      )}
      <div className={clsx(styles.chatDisplay, 'flex flex-col gap-3')}>
        {conversation.map(message => (
          <div key={`${message.id}-${message.timestamp}`}>
            <ConversationMessage message={message} updateConversation={setConversation} />
          </div>
        ))}
        {task && Object.keys(task).length > 0 && !task.completed && (
          <div className="flex flex-row items-start justify-center mt-2 py-4 bg-[#f3f3f3] rounded">
            <LoaderProgressWithDescription taskStatus={task} />
          </div>
        )}
        <div ref={refScrollBottomDiv} />
      </div>
      <div>
        <div className={clsx(styles.questionInputWrapper, styles.questionInputWrapperFooterOffset)}>
          <div className={styles.inputBorder}>
            <QuestionInputNew
              onInput={handleQuestionInput}
              disabled={isLoading || isPending || taskStatus?.completed === false}
              question={question}
              answerIsLoading={isPending}
              handleSubmit={() => handleSubmitQuestion(question)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
