import { WppToastContainer } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import styles from 'app/App.module.scss'
import RoutesCmp from 'app/Routes'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ProjectContext } from 'contexts/Project'
import { RfiCreateProps } from 'types/rfis/rfi'

export const EMPTY_PROJECT: RfiCreateProps = {
  projectName: '',
  client: '',
  pitchTypeId: '',
  activeStatus: 0,
  marketIds: [],
  agencies: [],
  projectMembers: [],
}

const EMPTY_STATE = {
  newProject: EMPTY_PROJECT,
  newTaskStarted: null,
  projectFiles: null,
}

export interface ProjectState {
  newProject: RfiCreateProps
  newTaskStarted: ProgressApiRes | null
  projectFiles: File[] | null
}

export function App() {
  const [state, setState] = useState<ProjectState>(EMPTY_STATE)
  const { osContext } = useOs()

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <div className={styles.container}>
        <div className={styles.pageContent}>
          <ProjectContext.Provider value={{ state, setState }}>
            <RoutesCmp />
          </ProjectContext.Provider>
        </div>
        <WppToastContainer />
      </div>
    </BrowserRouter>
  )
}
