import { TabsChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppBreadcrumb, WppIconChevron, WppTab, WppTabs, WppTypography } from '@wppopen/components-library-react'
import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { EditAddAgency } from 'pages/agency-dashboard/EditAddAgency'
import { Agency } from 'types/agencies/agencies'

import { AgencyEditKnowledgeBase } from './AgencyEditKnowledgeBase'

export const AgencyEditDetail = () => {
  const [currentTab, setCurrentTab] = useState('details')
  const { agencyId } = useParams()
  const { data: agencies } = useGetAgencies()
  const navigate = useNavigate()
  const agency: Agency | null = (agencyId && (agencies as Agency[]).find(agency => agency.id === agencyId)) || null

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value)
  }

  if (!agencyId || !agency) return <div> Sorry that id isn't recognised, so we're unable to find the agency.</div>
  return (
    <div>
      <div className="flex mb-4">
        <WppIconChevron direction="left" />
        <WppBreadcrumb
          onWppChange={event => navigate(event.detail.path)}
          items={[
            { label: 'Project Dashboard', path: '/' },
            { label: 'Agency Management', path: '/rfi-helper-tool/agency-dashboard' },
            { label: 'Agency Detail', path: `/rfi-helpder-tool/agency-dashboard/${agencyId}` },
          ]}
        />
      </div>

      <div className="flex justify-start mb-4">
        <WppTypography type="xl-heading">{agency.name}</WppTypography>
      </div>
      <WppTabs value={currentTab} onWppChange={handleTabChange} size="s">
        <WppTab className="w-96" value="details">
          Details
        </WppTab>
        <WppTab className="w-96" value="knowledgeBase">
          Knowledge Base
        </WppTab>
      </WppTabs>
      {
        {
          details: <EditAddAgency agency={agency} />,
          knowledgeBase: <AgencyEditKnowledgeBase agency={agency} />,
        }[currentTab]
      }
    </div>
  )
}
