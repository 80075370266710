import { rfiHelperApi } from 'api'
import { Agency, AgencyFetchDataParams } from 'types/agencies/agencies'

export const fetchAgencies = (data?: AgencyFetchDataParams) => {
  const entries =
    (data &&
      Object.entries(data)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')) ||
    null
  return rfiHelperApi.get<Agency[]>(`/agencies${entries ? '?' + entries : ''}`)
}
