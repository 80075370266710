// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--color-font-grey: #4d5358;--color-font-grey2: #121619;--color-font-greyIcon: #C1C7CD;--sizes-spacing-12: 12px;--sizes-spacing-16: 16px;--sizes-spacing-20: 20px;--page-min-height: calc(100vh - ( 1rem + var(--wpp-os-header-height)))}.JjK6k{min-height:var(--page-min-height);max-width:var(--wpp-os-content-max-width);margin:0 auto;padding-top:.9rem;background-color:var(--wpp-grey-color-100)}.Qcpco{width:100%;margin:0 auto;border-radius:12px;padding:10px 0px 10px 0px;background:var(--wpp-white-color);height:var(--page-min-height)}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,MACE,0BAAA,CACA,2BAAA,CACA,8BAAA,CACA,wBAAA,CACA,wBAAA,CACA,wBAAA,CACA,sEAAA,CAIF,OACG,iCAAA,CACA,yCAAA,CACA,aAAA,CACA,iBAAA,CACA,0CAAA,CAGH,OACE,UAAA,CACA,aAAA,CACA,kBAAA,CACA,yBAAA,CACA,iCAAA,CACA,6BAAA","sourcesContent":[":root{\n  --color-font-grey: #4d5358;\n  --color-font-grey2: #121619;\n  --color-font-greyIcon: #C1C7CD;\n  --sizes-spacing-12: 12px;\n  --sizes-spacing-16: 16px;\n  --sizes-spacing-20: 20px;\n  --page-min-height: calc(100vh - ( 1rem + var(--wpp-os-header-height)));\n\n}\n\n.container {\n   min-height: var(--page-min-height);\n   max-width: var(--wpp-os-content-max-width);\n   margin: 0 auto;\n   padding-top: 0.9rem; /* make sure there's now vertical scrollbar */\n   background-color: var(--wpp-grey-color-100);\n  }\n\n.pageContent {\n  width: 100%;\n  margin: 0 auto;\n  border-radius: 12px;\n  padding: 10px 0px 10px 0px;\n  background: var(--wpp-white-color);\n  height: var(--page-min-height)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `JjK6k`,
	"pageContent": `Qcpco`
};
export default ___CSS_LOADER_EXPORT___;
