/* eslint-disable react-hooks/exhaustive-deps */
import {
  WppIconClose,
  WppIconCopy,
  WppIconDone,
  WppIconEdit,
  WppSpinner,
  WppTextareaInput,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import Markdown from 'react-markdown'

import { usePatchRfiQuestionId } from 'api/mutations/rfis/usePatchRfiQuestionId'
import { getRfiQuestionById } from 'api/queries/rfis/useRfiQuestionId'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import stylesMain from 'assets/main.module.scss'
import { LoaderProgressWithDescription } from 'components/LoaderProgressWithDescription'
import useRfiQuestionContext from 'hooks/useRfiQuestionContext'
import { useToast } from 'hooks/useToast'

import styles from './ConversationMessage.module.scss'
import { Message, MessageType } from '../ChatCmp'

interface Props {
  message: Message
  updateConversation: React.Dispatch<React.SetStateAction<Message[]>>
}

export const ConversationMessage = ({ message, updateConversation }: Props) => {
  const { rfiQuestionId } = useRfiQuestionContext()
  const [{ data: rfiQuestion }, trigger] = getRfiQuestionById()
  const { showToast } = useToast()
  const [updating, setUpdating] = useState<string | null>(null)

  const { mutateAsync: editProject, isPending } = usePatchRfiQuestionId()
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: updating || '' },
    enabled: !!updating,
    refetchInterval: 2000,
  })

  const [isEditOpen, setIsEditOpen] = useState(false)
  const [content, setContent] = useState('')

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message.content)
    showToast({
      message: 'Message response copied to clipboard',
      type: 'success',
      duration: 4000,
    })
  }

  const handleChange = (event: any) => {
    setContent(event.target.value)
  }

  const handleCloseEdit = () => {
    setContent(message.content)
    setIsEditOpen(false)
  }

  const handleSubmit = async () => {
    try {
      const progress = await editProject({ question_id: rfiQuestionId || '', proposedAnswer: content })
      setIsEditOpen(false)
      setUpdating(progress.data.id)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (taskStatus?.completed) {
      setUpdating(null)
      trigger({ question_id: rfiQuestionId || '' })
      return
    }
  }, [taskStatus])

  useEffect(() => {
    if (rfiQuestion && rfiQuestion?.proposedAnswer !== message.content && message.type === MessageType.ANSWER) {
      updateConversation(prev =>
        prev.map(item => {
          if (item.id === message.id) {
            return {
              ...item,
              content: rfiQuestion?.proposedAnswer || '',
            }
          }
          return item
        }),
      )
    }
  }, [rfiQuestion])

  useEffect(() => {
    setContent(message.content)
  }, [message.content])

  return (
    <div>
      {!isEditOpen && !updating && (
        <div className={clsx(message.type === MessageType.QUESTION ? 'flex justify-end' : 'flex justify-start')}>
          <div
            className={clsx(
              message.type === MessageType.QUESTION ? ' bg-[#E0EBFF] rounded-8 p-3' : 'flex justify-start ',
            )}
          >
            <Markdown
              className={clsx(
                message.type === MessageType.QUESTION && stylesMain.blackPMobile,
                message.type === MessageType.ANSWER && stylesMain.captionregular,
                'whitespace-pre-wrap',
              )}
            >
              {message.content}
            </Markdown>
          </div>
        </div>
      )}
      {isEditOpen && (
        <WppTextareaInput
          name="email"
          placeholder="Ask a question to explore more options..."
          value={content}
          className={styles.textarea}
          onWppChange={handleChange}
        />
      )}

      {!!updating && (
        <div className="flex justify-center gap-4 mt-2">
          <LoaderProgressWithDescription taskStatus={taskStatus} />
        </div>
      )}

      {message.type === MessageType.ANSWER && (
        <div className="flex justify-end gap-4 mt-2">
          {!isEditOpen && (
            <>
              <div className="cursor-pointer" onClick={copyToClipboard}>
                <WppIconCopy />
              </div>
              <div className="cursor-pointer" onClick={() => setIsEditOpen(true)}>
                <WppIconEdit />
              </div>
            </>
          )}
          {isEditOpen && (
            <>
              <div className="cursor-pointer" onClick={handleCloseEdit}>
                <WppIconClose />
              </div>
              {isPending ? (
                <WppSpinner />
              ) : (
                <div className="cursor-pointer" onClick={handleSubmit}>
                  <WppIconDone />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}
