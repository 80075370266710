export enum API_TYPES {
  RFI_HELPER_API = 'RFI_HELPER_API',
  MASTER_DATA_API = 'MASTER_DATA_API',
  USERS_API = 'USERS_API',
}

const developmentApi: Record<API_TYPES, string> = {
  RFI_HELPER_API: 'https://rfi-helper-api-ch-hulk.os-dev.io/api',
  // RFI_HELPER_API: 'https://rfi-helper-api-ch-elektra.os-dev.io/api',
  MASTER_DATA_API: 'https://master-data-api-ch-hulk.os-dev.io/api',
  USERS_API: 'https://wpp.wpp-hulk.os-dev.io/api',
}

const productionApi: Record<API_TYPES, string> = {
  RFI_HELPER_API: 'https://rfi-helper-api-ch-stage.os-dev.io/api',
  MASTER_DATA_API: 'https://master-data-api-ch-stage.os-dev.io/api',
  USERS_API: 'https://facade-api-ch-stage.os-dev.io/api',
}

const config = Object.keys(API_TYPES).reduce(
  (acc, type) => {
    const api =
      process.env.NODE_ENV !== 'development'
        ? process.env[`REACT_APP_${type}`] || productionApi[type as API_TYPES]
        : developmentApi[type as API_TYPES]
    return { ...acc, [type]: api }
  },
  {} as Record<API_TYPES, string>,
)

export default config
