import { WppSpinner, WppTypography } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useNavigate } from 'react-router-dom'

import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useRfis } from 'api/queries/rfis/useRfis'
import { ProjectsFilterGroup } from 'components/ProjectsFilterGroup'
import { agencyRoles } from 'constants/agencyRoles'

import EmptyStateHome from './EmptyStateHome'
import ProjectPreviewCard from './ProjectPreviewCard'

export default function HomePage() {
  const navigate = useNavigate()
  const { osContext } = useOs()
  const {
    data: projects = [],
    error,
    isLoading,
  } = useRfis({
    params: {
      tenantId: osContext.tenant.id,
      userId: osContext.userDetails.id,
    },
  })
  const agencyPermission = osContext.permissions?.[0]?.permissions.includes(agencyRoles.RFI_AGENCY_MANAGE)
  // add to cache
  useGetAgencies()
  return (
    <>
      <div className="flex justify-start mb-4">
        <WppTypography type="2xl-heading">My Projects</WppTypography>
      </div>
      <div className="flex justify-center mb-4">
        <ProjectsFilterGroup navigate={navigate} agencyPermission={agencyPermission} />
      </div>
      {error && <EmptyStateHome />}
      {!error && isLoading && (
        <div className="flex flex-row items-start justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}
      {!error && projects && (
        <div className="flex flex-wrap justify-start gap-[1rem]">
          {projects?.map((project: any) => <ProjectPreviewCard key={project.id} {...project} />)}
        </div>
      )}
    </>
  )
}
