import {
  WppButton,
  WppIconAdd,
  WppInput,
  WppListItem,
  WppModal,
  WppTypography,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { prop, groupBy } from 'ramda'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAddRfiQuestion } from 'api/mutations/rfis/useAddRfiQuestion'
import { useSetQuestionPosition } from 'api/mutations/rfis/useSetQuestionPosition'
import { useRfiQuestions } from 'api/queries/rfis/useRfiQuestions'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { queryClient } from 'app/Root'
import ChatCmp from 'components/chat/ChatCmp'
import { MultipleContainers } from 'components/draggable-list/DraggableList'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { RfiQuestionContext } from 'contexts/RfiQuestion'
import { useToast } from 'hooks/useToast'
import { RfiQuestion } from 'types/rfis/rfi'

import styles from './AnswerQuestionsPage.module.scss'
interface ConvertedQuestion {
  id: string
  name: string
  questions: RfiQuestion[]
}

const convertQuestionsToCategories = groupBy<RfiQuestion>(prop('rfiCategoryId'))

export default function AnswerQuestionsPage() {
  const params = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [task, setTask] = useState<ProgressApiRes | null>(null)
  const { showToast } = useToast()
  const { data: taskStatus } = useTasksStatus({
    params: { taskId: task?.id || '' },
    enabled: !!task?.id,
    refetchInterval: 2000,
  })

  const {
    data: questions = [],
    isFetching,
    isLoading,
  } = useRfiQuestions({
    params: { rfiId: params.projectId || undefined },
  })
  const [convertedQuestions, setConvertedQuestions] = useState([] as ConvertedQuestion[])
  const [newQuestionText, setNewQuestionText] = useState('')
  const [selectedRfiQuestionId, setSelectedRfiQuestionId] = useState<string | null>(null)
  const {
    mutateAsync: addRfiQuestion,
    isPending,
    // isIdle,
  } = useAddRfiQuestion({
    onError: error => {
      showToast({
        message: error.message,
        type: 'error',
      })

      setIsOpen(false)
      setNewQuestionText('')
    },
  })
  const { mutateAsync: setQuestionPosition, isPending: setPositionPending } = useSetQuestionPosition()

  const addNewQuestion = async () => {
    if (newQuestionText.length === 0) {
      return
    }

    await addRfiQuestion({
      questionText: newQuestionText,
      rfiCategoryId: convertedQuestions[convertedQuestions.length - 1].id,
      rfiId: params.projectId || '',
    })
      .then(data => {
        setTask(data?.data)
        setNewQuestionText('')
        setIsOpen(false)
        showToast({
          message: 'Adding a new question. Please wait...',
          type: 'success',
        })
      })
      .catch(() => {
        showToast({
          message: 'Failed to add new question',
          type: 'error',
        })
        // setNewQuestionText('')
        // setIsOpen(false)
      })
  }

  const handleSavePositions = async (
    overContainer: string,
    prevId: string | null,
    nextId: string | null,
    activeId: string,
  ) => {
    setQuestionPosition({
      prevId,
      nextId,
      activeId,
      rfiCategoryId: overContainer,
    })
  }

  useEffect(() => {
    if (questions.length === 0 || isFetching || isLoading) {
      return
    }
    const groupedQuestions = convertQuestionsToCategories(questions)

    const result = Object.keys(groupedQuestions).map(categoryId => {
      const categoryQuestions = groupedQuestions[categoryId]
      const { rfiCategoryName } = categoryQuestions![0]
      return {
        id: categoryId.toString(),
        name: rfiCategoryName,
        questions:
          categoryQuestions?.map(q => ({
            ...q,
            id: q.id.toString(),
          })) || [],
      } as ConvertedQuestion
    })
    setConvertedQuestions(result)
  }, [questions, isFetching, isLoading])

  useEffect(() => {
    const { status } = taskStatus || {}
    if (status === 'completed') {
      setTask(null)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFI_QUESTIONS] }).then(() => {
        showToast({
          message: 'New question added successfully',
          type: 'success',
        })
      })
    }
  }, [taskStatus, showToast])

  if (isLoading || isFetching) {
    return <div>loading...</div>
  }

  return (
    <RfiQuestionContext.Provider
      value={{ rfiQuestionId: selectedRfiQuestionId, setRfiQuestionId: setSelectedRfiQuestionId }}
    >
      <div className="h-full">
        <WppTypography type="xl-heading">Answer Specific Questions in RFI</WppTypography>
        <div className="flex flex-row mt-3 items-stretch gap-3">
          <div className={clsx(styles.pageContentRight, 'flex-1')}>
            {convertedQuestions.length > 0 && (
              <MultipleContainers
                disabled={setPositionPending || isPending}
                questionsFromBe={questions}
                items={convertedQuestions}
                setItems={setConvertedQuestions}
                savePositions={handleSavePositions}
              />
            )}
            <WppListItem onClick={() => setIsOpen(true)}>
              <WppIconAdd slot="right" />
              <p slot="label">Add New Question</p>
            </WppListItem>
          </div>
          <div className={clsx(styles.chatContainer, 'flex-1')}>
            <ChatCmp />
          </div>
        </div>
        <WppModal open={isOpen}>
          <h3 slot="header">Add New Question</h3>
          <p slot="body">
            <WppInput
              labelConfig={{ text: 'Enter a new question' }}
              onWppChange={e => setNewQuestionText(e.detail.value ?? '')}
              value={newQuestionText}
              required
            />
          </p>
          <div className="flex flex-row items-center justify-end gap-2" slot="actions">
            <WppButton loading={isPending} variant="primary" size="s" onClick={addNewQuestion}>
              Add
            </WppButton>
            <WppButton
              disabled={isPending}
              variant="secondary"
              size="s"
              onClick={() => {
                setIsOpen(false)
                setNewQuestionText('')
              }}
            >
              Close
            </WppButton>
          </div>
        </WppModal>
      </div>
    </RfiQuestionContext.Provider>
  )
}
