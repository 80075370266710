import type { DraggableSyntheticListeners } from '@dnd-kit/core'
import type { Transform } from '@dnd-kit/utilities'
import {
  WppIconAvailableCheckmark,
  WppIconDragAndDrop,
  WppTooltip,
  WppTypography,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import React, { useEffect } from 'react'

import useRfiQuestionContext from 'hooks/useRfiQuestionContext'

import styles from './Item.module.scss'

export interface Props {
  dragOverlay?: boolean
  color?: string
  disabled?: boolean
  dragging?: boolean
  handle?: boolean
  handleProps?: any
  height?: number
  index?: number
  fadeIn?: boolean
  transform?: Transform | null
  listeners?: DraggableSyntheticListeners
  sorting?: boolean
  style?: React.CSSProperties
  transition?: string | null
  wrapperStyle?: React.CSSProperties
  value: React.ReactNode
  id: string
  alreadyAnswered?: boolean
  onRemove?(): void
  renderItem?(args: {
    dragOverlay: boolean
    dragging: boolean
    sorting: boolean
    index: number | undefined
    fadeIn: boolean
    listeners: DraggableSyntheticListeners
    ref: React.Ref<HTMLElement>
    style: React.CSSProperties | undefined
    transform: Props['transform']
    transition: Props['transition']
    value: Props['value']
    id: Props['id']
  }): React.ReactElement
}

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        index,
        listeners,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        id,
        wrapperStyle,
        alreadyAnswered,
      },
      ref,
    ) => {
      const { rfiQuestionId, setRfiQuestionId } = useRfiQuestionContext()
      // const [{ data: rfiQuestionApi }, trigger] = getRfiQuestionById()

      // useEffect(() => {
      //   if (rfiQuestionApi && rfiQuestionApi.id === id) {
      //     setRfiQuestion(rfiQuestionApi)
      //     const test1 = queryClient.getQueryCache()
      //     console.log('test1', test1)
      //     const test2 = queryClient.getQueriesData({ queryKey: [ApiQueryKeys.RFI_QUESTIONS] })
      //     console.log('test2', test2)
      //   }
      //   // eslint-disable-next-line react-hooks/exhaustive-deps
      // }, [rfiQuestionApi])

      useEffect(() => {
        if (!dragOverlay) {
          return
        }

        document.body.style.cursor = 'grabbing'

        return () => {
          document.body.style.cursor = ''
        }
      }, [dragOverlay])

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
          id,
        })
      ) : (
        <li
          className={clsx(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay,
            rfiQuestionId === id ? 'bg-[#E0EBFF] rounded-lg' : undefined,
          )}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),
              '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
              '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
              '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
              '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
              '--index': index,
              '--color': rfiQuestionId === id ? 'blue' : color,
            } as React.CSSProperties
          }
          ref={ref}
        >
          <button
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            tabIndex={!handle ? 0 : undefined}
          >
            <WppIconDragAndDrop color="gray" />
          </button>
          <div
            className={clsx(
              styles.Item,
              dragging && styles.dragging,
              handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color,
              rfiQuestionId === id ? 'bg-[#E0EBFF]' : undefined,
            )}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              if (!disabled) {
                // trigger({ question_id: id })
                setRfiQuestionId(id)
                // queryClient.invalidateQueries({
                //   queryKey: [ApiQueryKeys.RFI_QUESTION],
                // })
              }
            }}
          >
            <WppTypography className={clsx(rfiQuestionId === id ? 'text-[#0014CC]' : '')} type="s-body">
              {value}
            </WppTypography>
          </div>
          <div className="flex flex-row items-center">
            {alreadyAnswered ? (
              <WppTooltip text="For this question, an answer has already been proposed">
                <WppIconAvailableCheckmark />
              </WppTooltip>
            ) : null}
          </div>
        </li>
      )
    },
  ),
)
