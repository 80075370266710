import { WppButton, WppNavSidebar } from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useUploadFile } from 'api/mutations/pdfs/useUploadFile'
import { useCreateRfi } from 'api/mutations/rfis/useCreateRfi'
import { ProjectState } from 'app/App'
import useProjectContext from 'hooks/useProjectContext'
import { useToast } from 'hooks/useToast'

import styles from './SidebarCmp.module.scss'
import { NavigationItems } from './SidebarCmpNavItems'

export const navigation = {
  home: '/rfi-helper-tool/home',
  newProject: '/rfi-helper-tool/new-project',
  agencyDashboard: '/rfi-helper-tool/agency-dashboard',
  rfiSummary: '/rfi-helper-tool/rfi-summary',
  relevantNotes: '/rfi-helper-tool/relevant-notes',
  answerQuestions: '/rfi-helper-tool/answer-questions',
  draftResponse: '/rfi-helper-tool/ai-draft-response',
}

const isReadyToSave = (projectState: ProjectState | null) => {
  if (!projectState?.newProject) return false
  let isValid = false

  Object.entries(projectState.newProject).forEach(([key, value]: [key: string, value: any]) => {
    if (key !== 'projectMembers' && Boolean(value)) {
      isValid = true
    }
  })
  return projectState.projectFiles && isValid
}

export function SidebarCmp() {
  const navigate = useNavigate()
  const [active, setActive] = useState('')
  const { projectId, agencyId } = useParams()
  const location = useLocation()
  const saveProject = useCreateRfi()
  const toast = useToast()
  const { state, setState } = useProjectContext()
  const { mutateAsync: uploadFile } = useUploadFile()

  useEffect(() => {
    setTimeout(() => {
      setActive(location.pathname)
    }, 0)
  }, [location.pathname])

  if ([navigation.home, navigation.agencyDashboard].includes(location.pathname) || agencyId) {
    return null
  }

  const handleChangeRoute = (event: CustomEvent) => {
    navigate(event.detail.path)
  }

  const handleFileUpload = async (projectId: string) => {
    try {
      if (state.projectFiles) {
        const formData = new FormData()
        const files = state.projectFiles.map(file => new Blob([file], { type: 'application/pdf' }))
        files.forEach((file, index) => {
          formData.append('file', file, state.projectFiles?.[index].name)
        })
        uploadFile({ projectId, formData }).then(result => {
          setState(prev => ({
            ...prev,
            newTaskStarted: result.data,
          }))
        })
      }
    } catch (e) {
      toast.showToast({
        type: 'error',
        message: 'Failed to upload file',
      })
    }
  }

  const handleNext = () => {
    const index = Object.values(navigation).findIndex(path => location.pathname.includes(path))
    if (location.pathname.includes(navigation.newProject) && isReadyToSave(state) && !projectId) {
      saveProject
        .mutateAsync({
          ...state.newProject!,
          activeStatus: 1,
          agencies: state.newProject.agencies.map(agency => ({ id: agency })) as { id: string }[],
          projectMembers: state.newProject.projectMembers,
        })
        .then(result => {
          handleFileUpload(result.data.id)
        })
        .catch(() => {
          toast.showToast({
            type: 'error',
            message: 'Failed to save project',
          })
          setState(prev => ({
            ...prev,
            newTaskStarted: null,
          }))
        })
    }
    if (!projectId) return
    navigate(`${Object.values(navigation)[index + 1]}/${projectId}`)
  }

  const handleBack = () => {
    const index = Object.values(navigation).findIndex(path => location.pathname.includes(path))
    navigate(`${Object.values(navigation)[index - 1]}/${projectId}`)
  }
  if (!projectId && location.pathname.includes(navigation.home)) {
    return null
  }

  const disableItems = clsx(!projectId ? 'opacity-50 hover:cursor-not-allowed pointer-events-none' : '')
  const disabledBack = active.includes(navigation.newProject) || active.includes(navigation.rfiSummary)
  let disabledNext = active.includes(navigation.newProject)
    ? !isReadyToSave(state) ||
      state.newTaskStarted !== null ||
      saveProject.isPending ||
      active.includes(navigation.draftResponse)
    : active.includes(navigation.draftResponse) || false

  return (
    <div className={styles.sidebarContainer}>
      <WppNavSidebar className={styles.sidebar} onWppChange={handleChangeRoute}>
        <NavigationItems
          navigation={navigation}
          newTaskStarted={Boolean(state.newTaskStarted)}
          disableItems={disableItems}
          projectId={projectId || ''}
          active={active}
        />
        <div className="h-8" />
        {!location.pathname.includes(navigation.home) && (
          <div className="flex flex-row justify-end items-center gap-3 absolute bottom-0 right-3">
            <WppButton onClick={handleBack} disabled={disabledBack} variant="secondary">
              Back
            </WppButton>
            <WppButton disabled={disabledNext} loading={saveProject.isPending} onClick={handleNext}>
              Next
            </WppButton>
          </div>
        )}
      </WppNavSidebar>
    </div>
  )
}
