import { PaginationChangeEventDetail } from '@wppopen/components-library'
import { WppPagination } from '@wppopen/components-library-react'
import { useEffect, useState } from 'react'

import { Agency } from 'types/agencies/agencies'

import AgencyCard from '../AgencyCard'
import { AgencyListProps } from '../types'

export const paginated = (page: number, currPage: number, pageSize: number, data: Agency[]) =>
  data.slice(page * pageSize, currPage * pageSize)

export const AgencyCards = ({ agencies, handleEditAgencyClick, handleDeleteAgencyClick }: AgencyListProps) => {
  const [currPage, setCurrPage] = useState<number>(1)
  const [paginationPageSize, setPaginationPageSize] = useState<number>(10)

  const handlePaginationChange = async (event: CustomEvent<PaginationChangeEventDetail>) => {
    const { itemsPerPage, page } = event.detail
    if (paginationPageSize !== itemsPerPage) {
      setPaginationPageSize(itemsPerPage)
    }
    if (currPage !== page) {
      setCurrPage(page)
    }
  }

  useEffect(() => {
    /* make sure if new search filtered data lower than 1 page - default to first results page */
    if (Math.floor(agencies.length / paginationPageSize) < paginationPageSize) {
      setCurrPage(1)
    }
  }, [agencies.length, paginationPageSize])

  return (
    <>
      <div className="flex flex-row justify-start flex-wrap gap-4">
        {paginated(currPage - 1, currPage, paginationPageSize, agencies)?.map((agency: Agency) => (
          <AgencyCard
            key={agency.id}
            {...agency}
            handleEditAgencyClick={handleEditAgencyClick}
            handleDeleteAgencyClick={handleDeleteAgencyClick}
          />
        ))}
      </div>
      {agencies.length > paginationPageSize && (
        <WppPagination
          count={agencies.length}
          itemsPerPage={[10, 20, 50, 100]}
          activePageNumber={currPage}
          pageSelectThreshold={9}
          data-testid="pagination-list"
          selectedItemPerPage={paginationPageSize}
          onWppChange={handlePaginationChange}
        />
      )}
    </>
  )
}
