// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swbG0::part(wrapper){padding:4px 0 0 0}.RjN0j::part(typography){font-size:14px;color:var(--color-font-grey);margin-top:.25rem}`, "",{"version":3,"sources":["webpack://./src/pages/new-project/NewProjectPage.module.scss"],"names":[],"mappings":"AACI,sBACI,iBAAA,CAKJ,yBACI,cAAA,CACA,4BAAA,CACA,iBAAA","sourcesContent":[".inputInlineMessage{\n    &::part(wrapper){\n        padding: 4px 0 0 0;\n    }\n }\n\n .subText {\n    &::part(typography) {\n        font-size: 14px;\n        color: var(--color-font-grey);\n        margin-top: 0.25rem;\n    }\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputInlineMessage": `swbG0`,
	"subText": `RjN0j`
};
export default ___CSS_LOADER_EXPORT___;
