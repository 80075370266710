import { ImageAttribute } from '../types'

/**
 * Extract the height and width attributes of the image tags in the HTML string
 * @param htmlString The HTML string to extract the image attributes from
 * @returns The height and width attributes of the images in the HTML string
 * */
export const setImageAttributesFromDomNode = (node: HTMLElement) => {
  const imgs = node?.getElementsByTagName('img') || []
  const imgAttributes: ImageAttribute[] = []
  if (imgs?.length > 0) {
    for (let i = 0; i < imgs.length; i++) {
      const img = imgs[i]
      const imgHeight = img.clientHeight
      const imgWidth = img.clientWidth
      const imgTop = img.offsetTop
      const imgLeft = img.offsetLeft
      imgAttributes.push({ height: imgHeight, width: imgWidth, top: imgTop, left: imgLeft })
    }
  }
  return imgAttributes
}
/**
 * Update the height and width attributes of the image tags in the HTML string
 * @param htmlString The HTML string to update
 * @param imageAttributes The new height and width attributes for the images
 * @returns The updated HTML string
 
 * */
export const updateImageAttributesInString = (htmlString: string, imageAttributes: ImageAttribute[]) => {
  const dimensions = [...imageAttributes]

  return htmlString.replace(/<img([^>]+)>/gi, (match, attrs) => {
    const imgAttrs: { [key: string]: any } = {} // Add index signature to allow indexing with a string

    attrs.replace(/(\w+)="([^"]+)"/g, (_: unknown, name: string | number, value: any) => (imgAttrs[name] = value))
    const { height, width } = dimensions.shift() || {}

    if (height && width) {
      imgAttrs.style = `height:${height}px;width:${width}px`
    }
    return `<img ${Object.entries(imgAttrs)
      .map(([name, value]) => `${name}="${value}"`)
      .join(' ')}>`
  })
}
