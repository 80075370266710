import {
  WppFileUpload,
  WppInlineMessage,
  WppInput,
  WppListItem,
  WppSelect,
  WppTypography,
} from '@wppopen/components-library-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useMarkets } from 'api/queries/markets/useMarkets'
import { useRegions } from 'api/queries/markets/useRegions'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { EMPTY_PROJECT, ProjectState } from 'app/App'
import { queryClient } from 'app/Root'
import { LoaderProgressWithDescription } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import useProjectContext from 'hooks/useProjectContext'
import { useToast } from 'hooks/useToast'
import RfiSummaryError from 'pages/rfi-summary/RfiSummaryError'
import { RfiCreateProps } from 'types/rfis/rfi'
import { Role } from 'types/users/userList'

import style from './NewProjectPage.module.scss'

const UPLOAD_PROCESSING_TIMER = 300

export default function NewProjectPage() {
  // const {
  //   osContext: {
  //     userDetails: { id: currentUserId },
  //   },
  // } = useOs()

  // users selection state
  // const [usersSelected, setUsersSelected] = useState<SelectedAutoCompleteOption[]>([])
  // const [usersAdded, setUsersAdded] = useState<SelectedAutoCompleteOption[]>([])
  // const [userToRemove, setUserToRemove] = useState<SelectedAutoCompleteOption | null>(null)

  const [isProcessing, setIsProcessing] = useState(false)
  const [uploadTimer, setUploadTimer] = useState(UPLOAD_PROCESSING_TIMER)
  const [showError, setShowError] = useState(false)
  // const [isModalOpen, setIsModalOpen] = useState(false)
  // const [{ data: usersResult, isLoading }, userSearchTrigger] = useSearchUsers()

  const navigate = useNavigate()
  const { data: markets = [] } = useMarkets()
  const { data: agencies = [] } = useGetAgencies()
  const { data: regions = [] } = useRegions()
  const { data: pitchTypes = [] } = usePitchTypes()
  const { state, setState } = useProjectContext()
  const { data: taskStatus } = useTasksStatus({
    enabled: !!state.newTaskStarted?.id,
    refetchInterval: 3000,
    params: { taskId: state.newTaskStarted?.id || '' },
  })

  const toast = useToast()
  const isTaskRunning = state.newTaskStarted !== null && !taskStatus.completed

  useEffect(() => {
    if (taskStatus?.completed && !taskStatus.error) {
      setState(prev => ({
        ...prev,
        projectId: taskStatus?.resultObjectId || null,
        newTaskStarted: null,
        projectFiles: null,
        newProject: EMPTY_PROJECT,
      }))
      toast.showToast({
        message: 'Project created successfully',
        type: 'success',
      })
      // setUsersAdded([])
      // setUserToRemove(null)
      navigate(`/rfi-helper-tool/rfi-summary/${taskStatus?.resultObjectId || ''}`)
    }
    if (taskStatus?.error) {
      setState(prev => ({
        ...prev,
        newTaskStarted: null,
      }))
      setShowError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatus?.completed])

  // stop request if request takes longer than 5 mins
  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if ((isProcessing || isTaskRunning) && uploadTimer === 0) {
      queryClient.cancelQueries({ queryKey: [ApiQueryKeys.TASKS_STATUS] })
      setState({
        newProject: EMPTY_PROJECT,
        newTaskStarted: null,
        projectFiles: null,
      })
      setShowError(true)
    }
    if ((isProcessing || isTaskRunning) && uploadTimer > 0) {
      intervalId = setInterval(() => {
        setUploadTimer(prev => --prev)
      }, 1000)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [uploadTimer, isTaskRunning, isProcessing, setState])

  const updateState = (
    key: keyof RfiCreateProps,
    value: string | string[] | { member: string; role: keyof typeof Role }[],
  ) => {
    setState(
      prev =>
        ({
          ...prev,
          newProject: {
            ...prev.newProject,
            [key]: value,
          },
        }) as ProjectState,
    )
  }

  const handleFileUploadChange = (event: CustomEvent) =>
    setState(prev => ({ ...prev, projectFiles: event.detail.value }))

  const onRetryNewProject = () => {
    setShowError(false)
    setUploadTimer(UPLOAD_PROCESSING_TIMER)
    setIsProcessing(false)
    // setUsersAdded([])
  }

  // const onUserSearch = debounce((e: WppAutocompleteCustomEvent<string>) => {
  //   userSearchTrigger({
  //     username: e.detail,
  //   })
  // }, 200)

  // const getRfiMembers = (users: IUser[]) =>
  //   users.map(user => ({ member: user.email, role: user.role?.name ?? Role.Contributor }))

  // const onAutoCompleteClickOutside = (e: Event) => {
  //   const role = (e.target as EventTarget & { getAttribute: (x: string) => string }).getAttribute('role')
  //   if (!['WPP-LIST-ITEM', 'checkbox'].includes(role)) {
  //     const updatedUsers = [...usersAdded, ...usersSelected]
  //     setUsersAdded(updatedUsers)
  //     updateState('projectMembers', getRfiMembers(updatedUsers))
  //     setTimeout(() => {
  //       setUsersSelected([])
  //     }, 200)
  //   }
  // }

  // const onRoleChange = ({ userId, role }: { userId: string; role: IUserRole }) => {
  //   const updatedUsers = usersAdded.map(user => {
  //     if (user.id === userId) {
  //       return { ...user, role }
  //     }
  //     return user
  //   })
  //   setUsersAdded(updatedUsers)
  //   updateState('projectMembers', getRfiMembers(updatedUsers))
  // }

  // const isUserSelected = (user: IUser) => {
  //   return usersAdded.some(usr => usr.id === user.id) || user.id === currentUserId
  // }

  // const toggleModal = () => setIsModalOpen(prev => !prev)

  const uploadDisabled =
    !state.newProject?.client ||
    !state.newProject?.projectName ||
    !state.newProject?.pitchTypeId ||
    !state.newProject.agencies.length ||
    (state.newProject?.marketIds?.length || 0) < 1

  const disabledInputsCondition = isProcessing || isTaskRunning

  return (
    <div className="flex flex-col gap-3">
      {showError && <RfiSummaryError onRetry={onRetryNewProject} />}
      {!showError && (
        <>
          <WppTypography type="2xl-heading">New Project</WppTypography>
          <div className="mt-4 flex flex-col gap-3">
            <WppTypography type="xl-heading">Information</WppTypography>
            <div className="flex flex-row gap-3">
              <div className="flex-1">
                <WppInput
                  value={state.newProject?.projectName}
                  disabled={disabledInputsCondition || undefined}
                  onWppChange={e => updateState('projectName', e.detail.value || '')}
                  required
                  labelConfig={{
                    text: 'Project Name',
                  }}
                />
              </div>
              <div className="flex-1">
                <WppSelect
                  disabled={disabledInputsCondition || undefined}
                  value={state.newProject?.pitchTypeId}
                  onWppChange={e => updateState('pitchTypeId', e.detail.value || '')}
                  required
                  labelConfig={{
                    text: 'Pitch Type',
                  }}
                >
                  {pitchTypes.map(pitchType => (
                    <WppListItem key={pitchType.id} value={pitchType.id}>
                      <p slot="label">{pitchType.typeDescription}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <div className="flex-1">
                <WppInput
                  disabled={disabledInputsCondition || undefined}
                  value={state.newProject?.client || ''}
                  onWppChange={e => updateState('client', e.detail.value || '')}
                  required
                  labelConfig={{
                    text: 'Client Name',
                  }}
                />
              </div>
              <div className="flex-1">
                <WppSelect
                  required
                  disabled={disabledInputsCondition || undefined}
                  withSearch={true}
                  showSelectAllText={true}
                  type="multiple"
                  value={state.newProject?.marketIds || []}
                  onWppChange={e => updateState('marketIds', e.detail.value)}
                  labelConfig={{
                    text: 'Markets',
                  }}
                >
                  {[...regions, ...markets].map(market => (
                    <WppListItem key={market.id} value={market.id}>
                      <p slot="label">{market.name}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <div className="flex-1">
                <WppSelect
                  disabled={disabledInputsCondition || undefined}
                  value={state.newProject?.agencies}
                  required
                  labelConfig={{
                    text: 'Agency',
                  }}
                  placeholder="Select Agency"
                  withSearch={true}
                  onWppChange={e => updateState('agencies', e.detail.value)}
                  showSelectAllText={true}
                  type="multiple"
                >
                  {agencies.map(agency => {
                    return (
                      <WppListItem key={agency.id} value={agency.id}>
                        <p slot="label">{agency.name}</p>
                      </WppListItem>
                    )
                  })}
                </WppSelect>
                <WppInlineMessage
                  className={style.inputInlineMessage}
                  message="Select the relevant agency (one or more) and its knowledge base for this project."
                />
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col mt-4">
            <WppTypography type="xl-heading">Team members</WppTypography>
            <WppTypography type="m-body" className={style.subText}>
              Invite Users to Collaborate on this project
            </WppTypography>
            <div className="mt-7 mb-10 relative">
              <WppAutocomplete
                required
                name="users"
                placeholder="Select member(s)"
                disabled={disabledInputsCondition || undefined}
                value={usersSelected}
                onWppChange={e => setUsersSelected(e.detail.value as SelectedAutoCompleteOption[])}
                onWppSearchValueChange={onUserSearch}
                dropdownConfig={{
                  onClickOutside: (_, e) => onAutoCompleteClickOutside(e),
                }}
                multiple
                loading={isLoading}
              >
                {usersResult?.data
                  ?.map(u => ({ ...u, label: `${u.firstname} ${u.lastname}` }))
                  .map(user => (
                    <WppListItem
                      className="p-2"
                      key={user.id}
                      value={user}
                      label={user.label}
                      disabled={isUserSelected(user)}
                    >
                      <div className="flex flex-col gap-1 justify-center">
                        <p slot="label">{user.label}</p>
                        <p slot="caption">{user.email}</p>
                      </div>
                      <WppAvatar size="xs" src={user.avatarUrl} slot="left" />
                    </WppListItem>
                  ))}
                <SelectedValues
                  values={usersSelected}
                  onCloseClick={value => {
                    setUsersSelected(usersSelected.filter(i => i.id !== value))
                  }}
                />
              </WppAutocomplete>
            </div>
            <UsersList
              users={usersAdded}
              onRoleChange={onRoleChange}
              disabled={isProcessing || isTaskRunning}
              onRemoveUser={user => {
                setUserToRemove(user as SelectedAutoCompleteOption)
                toggleModal()
              }}
            />
          </div> */}

          <WppTypography type="xl-heading" className="mt-4">
            Upload files
          </WppTypography>
          <WppFileUpload
            name="rfiProjectPdf"
            disabled={uploadDisabled || isProcessing || isTaskRunning}
            acceptConfig={{ 'application/pdf': ['.pdf'] }}
            onWppChange={handleFileUploadChange}
            format="arrayBuffer"
          />
          {/* TODO: adjust it once the backed is ready to upload RFI */}
          {(isProcessing || isTaskRunning) && (
            <div className="h-[146px] rounded-lg w-full flex flex-row items-center justify-center p-[10px] bg-[#F8F9FB]">
              <LoaderProgressWithDescription taskStatus={taskStatus} />
            </div>
          )}
          {state.newTaskStarted !== null && !taskStatus.completed ? (
            <div className="flex flex-row items-center justify-start bg-[#ffffcc] rounded-lg border border-solid border-[#ffeb3b] gap-3 mt-4 px-5 py-4">
              <WppTypography type="s-body">
                The project will be created once the file is uploaded and processed. Please wait...
              </WppTypography>
            </div>
          ) : null}
        </>
      )}
      {/* <WppModal open={isModalOpen}>
        <h3 slot="header">Remove Member?</h3>
        <p slot="body">
          Are you sure you want to remove <strong>{`${userToRemove?.firstname} ${userToRemove?.lastname}`}</strong>?
        </p>
        <div slot="actions" className="flex gap-2 justify-end">
          <WppButton variant="secondary" size="s" onClick={toggleModal}>
            Cancel
          </WppButton>
          <WppButton
            variant="destructive"
            size="s"
            onClick={() => {
              toggleModal()
              const updatedUsers = usersAdded.filter(u => u.id !== userToRemove?.id)
              setUsersAdded(updatedUsers)
              updateState('projectMembers', getRfiMembers(updatedUsers))
            }}
          >
            Remove
          </WppButton>
        </div>
      </WppModal> */}
    </div>
  )
}
